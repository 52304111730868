import React from 'react';
import { Link } from '@reach/router';
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
} from 'react-share';

import './footer/footer.sass';

//import share from '../../images/icons/share.svg';
import contact from '../../images/icons/contact.svg';

const Footer = () => {
  return (
    <nav className="navbar">
      <div className="container">
        <div className="navbar-brand">
          <div className="navbar-start">
            {/* <Link
              to="//www.lattecreative.com"
              target="_blank"
              className="navbar-item is-hidden-touch"
            >
              Designed with Latte Creative
            </Link> */}
            <button className="navbar-item button is-text">
              <span className="is-hidden-touch">Condividi</span>{' '}
              {/*<img src={share} className="svg-icon is-share" alt="" />*/}
              &nbsp;
              <FacebookShareButton
                url={window.location.href.replace(/\/play$/, '')}
                quote={
                  document.head.querySelector('[name=description]').content
                }
              >
                <FacebookIcon
                  size={22}
                  round={true}
                  iconBgStyle={{ fill: 'rgba(255, 255, 255, 0.2)' }}
                />
              </FacebookShareButton>
              &nbsp;
              <TwitterShareButton
                url={window.location.href.replace(/\/play$/, '')}
                title={
                  document.head.querySelector('[name=description]').content
                }
              >
                <TwitterIcon
                  size={22}
                  round={true}
                  iconBgStyle={{ fill: 'rgba(255, 255, 255, 0.2)' }}
                />
              </TwitterShareButton>
            </button>
            <Link
              to="/il-progetto#disclaimer"
              className="navbar-item is-hidden-touch"
            >
              Questo sito è un gioco
            </Link>
          </div>

          <div className="navbar-end">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:info@riparteilfuturo.it"
              className="navbar-item button is-text"
            >
              Contattaci{' '}
              <img src={contact} className="svg-icon is-contact" alt="" />
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Footer;
